import { CALL_API } from '../middleware/api'

export const CUSTOMERS_REQUEST = 'CUSTOMERS_REQUEST'
export const CUSTOMERS_SUCCESS = 'CUSTOMERS_SUCCESS'
export const CUSTOMERS_FAILURE = 'CUSTOMERS_FAILURE'

export function fetchCustomers() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'customers',
      authenticated: true,
      types: [CUSTOMERS_REQUEST, CUSTOMERS_SUCCESS, CUSTOMERS_FAILURE]
    }
  }
}

export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST'
export const CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS'
export const CUSTOMER_FAILURE = 'CUSTOMER_FAILURE'

export function fetchCustomer(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `customers/${id}`,
      authenticated: true,
      types: [CUSTOMER_REQUEST, CUSTOMER_SUCCESS, CUSTOMER_FAILURE]
    }
  }
}

export const CUSTOMER_ADD_REQUEST = 'CUSTOMER_ADD_REQUEST'
export const CUSTOMER_ADD_SUCCESS = 'CUSTOMER_ADD_SUCCESS'
export const CUSTOMER_ADD_FAILURE = 'CUSTOMER_ADD_FAILURE'

export function addCustomer(customer) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'customers',
      authenticated: true,
      body: JSON.stringify(customer),
      types: [CUSTOMER_ADD_REQUEST, CUSTOMER_ADD_SUCCESS, CUSTOMER_ADD_FAILURE]
    }
  }
}

export const CUSTOMER_UPDATE_REQUEST = 'CUSTOMER_UPDATE_REQUEST'
export const CUSTOMER_UPDATE_SUCCESS = 'CUSTOMER_UPDATE_SUCCESS'
export const CUSTOMER_UPDATE_FAILURE = 'CUSTOMER_UPDATE_FAILURE'

export function updateCustomer(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `customers/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [CUSTOMER_UPDATE_REQUEST, CUSTOMER_UPDATE_SUCCESS,
              CUSTOMER_UPDATE_FAILURE]
    }
  }
}

export function updateClient(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `customers/client/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [CUSTOMER_UPDATE_REQUEST, CUSTOMER_UPDATE_SUCCESS,
              CUSTOMER_UPDATE_FAILURE]
    }
  }
}

export const CUSTOMER_RESET = 'CUSTOMER_RESET'

export function resetCustomer() {
  return { type: CUSTOMER_RESET }
}

// CASH CONTROL

export const CUSTOMERSCASH_REQUEST = 'CUSTOMERSCASH_REQUEST'
export const CUSTOMERSCASH_SUCCESS = 'CUSTOMERSCASH_SUCCESS'
export const CUSTOMERSCASH_FAILURE = 'CUSTOMERSCASH_FAILURE'

export function fetchCustomersCash() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'customers/cash',
      authenticated: true,
      types: [CUSTOMERSCASH_REQUEST, CUSTOMERSCASH_SUCCESS, CUSTOMERSCASH_FAILURE]
    }
  }
}

export const CUSTOMERCASH_REQUEST = 'CUSTOMERCASH_REQUEST'
export const CUSTOMERCASH_SUCCESS = 'CUSTOMERCASH_SUCCESS'
export const CUSTOMERCASH_FAILURE = 'CUSTOMERCASH_FAILURE'

export function fetchCustomerCash(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `customers/cash/${id}`,
      authenticated: true,
      types: [CUSTOMERCASH_REQUEST, CUSTOMERCASH_SUCCESS, CUSTOMERCASH_FAILURE]
    }
  }
}

export const CUSTOMERCASH_ADD_REQUEST = 'CUSTOMERCASH_ADD_REQUEST'
export const CUSTOMERCASH_ADD_SUCCESS = 'CUSTOMERCASH_ADD_SUCCESS'
export const CUSTOMERCASH_ADD_FAILURE = 'CUSTOMERCASH_ADD_FAILURE'

export function addCustomerCash(customer, id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `customers/cash/${id}`,
      authenticated: true,
      body: JSON.stringify(customer),
      types: [CUSTOMERCASH_ADD_REQUEST, CUSTOMERCASH_ADD_SUCCESS, CUSTOMERCASH_ADD_FAILURE]
    }
  }
}

export const CUSTOMERCASH_UPDATE_REQUEST = 'CUSTOMERCASH_UPDATE_REQUEST'
export const CUSTOMERCASH_UPDATE_SUCCESS = 'CUSTOMERCASH_UPDATE_SUCCESS'
export const CUSTOMERCASH_UPDATE_FAILURE = 'CUSTOMERCASH_UPDATE_FAILURE'

export function updateCustomerCash(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `customers/cash/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [CUSTOMERCASH_UPDATE_REQUEST, CUSTOMERCASH_UPDATE_SUCCESS,
              CUSTOMERCASH_UPDATE_FAILURE]
    }
  }
}

// PDA CONTROL

export const CUSTOMERSPDA_REQUEST = 'CUSTOMERSPDA_REQUEST'
export const CUSTOMERSPDA_SUCCESS = 'CUSTOMERSPDA_SUCCESS'
export const CUSTOMERSPDA_FAILURE = 'CUSTOMERSPDA_FAILURE'

export function fetchCustomersPDA() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'customers/pda',
      authenticated: true,
      types: [CUSTOMERSPDA_REQUEST, CUSTOMERSPDA_SUCCESS, CUSTOMERSPDA_FAILURE]
    }
  }
}

export const CUSTOMERPDA_REQUEST = 'CUSTOMERPDA_REQUEST'
export const CUSTOMERPDA_SUCCESS = 'CUSTOMERPDA_SUCCESS'
export const CUSTOMERPDA_FAILURE = 'CUSTOMERPDA_FAILURE'

export function fetchCustomerPDA(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `customers/pda/${id}`,
      types: [CUSTOMERPDA_REQUEST, CUSTOMERPDA_SUCCESS, CUSTOMERPDA_FAILURE]
    }
  }
}

export const CUSTOMERPDA_ADD_REQUEST = 'CUSTOMERPDA_ADD_REQUEST'
export const CUSTOMERPDA_ADD_SUCCESS = 'CUSTOMERPDA_ADD_SUCCESS'
export const CUSTOMERPDA_ADD_FAILURE = 'CUSTOMERPDA_ADD_FAILURE'

export function addCustomerPDA(customer, id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `customers/pda/${id}`,
      authenticated: true,
      body: JSON.stringify(customer),
      types: [CUSTOMERPDA_ADD_REQUEST, CUSTOMERPDA_ADD_SUCCESS, CUSTOMERPDA_ADD_FAILURE]
    }
  }
}

export const CUSTOMERPDA_UPDATE_REQUEST = 'CUSTOMERPDA_UPDATE_REQUEST'
export const CUSTOMERPDA_UPDATE_SUCCESS = 'CUSTOMERPDA_UPDATE_SUCCESS'
export const CUSTOMERPDA_UPDATE_FAILURE = 'CUSTOMERPDA_UPDATE_FAILURE'

export function updateCustomerPDA(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `customers/pda/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [CUSTOMERPDA_UPDATE_REQUEST, CUSTOMERPDA_UPDATE_SUCCESS,
              CUSTOMERPDA_UPDATE_FAILURE]
    }
  }
}

// GROUP CONTROL

export const CUSTOMERSGROUP_REQUEST = 'CUSTOMERSGROUP_REQUEST'
export const CUSTOMERSGROUP_SUCCESS = 'CUSTOMERSGROUP_SUCCESS'
export const CUSTOMERSGROUP_FAILURE = 'CUSTOMERSGROUP_FAILURE'

export function fetchCustomersGroup() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'customers/group',
      authenticated: true,
      types: [CUSTOMERSGROUP_REQUEST, CUSTOMERSGROUP_SUCCESS, CUSTOMERSGROUP_FAILURE]
    }
  }
}

export const CUSTOMERGROUP_REQUEST = 'CUSTOMERGROUP_REQUEST'
export const CUSTOMERGROUP_SUCCESS = 'CUSTOMERGROUP_SUCCESS'
export const CUSTOMERGROUP_FAILURE = 'CUSTOMERGROUP_FAILURE'

export function fetchCustomerGroup(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `customers/group/${id}`,
      authenticated: true,
      types: [CUSTOMERGROUP_REQUEST, CUSTOMERGROUP_SUCCESS, CUSTOMERGROUP_FAILURE]
    }
  }
}

export const CUSTOMERGROUP_ADD_REQUEST = 'CUSTOMERGROUP_ADD_REQUEST'
export const CUSTOMERGROUP_ADD_SUCCESS = 'CUSTOMERGROUP_ADD_SUCCESS'
export const CUSTOMERGROUP_ADD_FAILURE = 'CUSTOMERGROUP_ADD_FAILURE'

export function addCustomerGroup(customer) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `customers/group`,
      authenticated: true,
      body: JSON.stringify(customer),
      types: [CUSTOMERGROUP_ADD_REQUEST, CUSTOMERGROUP_ADD_SUCCESS, CUSTOMERGROUP_ADD_FAILURE]
    }
  }
}

export const CUSTOMERGROUP_UPDATE_REQUEST = 'CUSTOMERGROUP_UPDATE_REQUEST'
export const CUSTOMERGROUP_UPDATE_SUCCESS = 'CUSTOMERGROUP_UPDATE_SUCCESS'
export const CUSTOMERGROUP_UPDATE_FAILURE = 'CUSTOMERGROUP_UPDATE_FAILURE'

export function updateCustomerGroup(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `customers/group/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [CUSTOMERGROUP_UPDATE_REQUEST, CUSTOMERGROUP_UPDATE_SUCCESS,
              CUSTOMERGROUP_UPDATE_FAILURE]
    }
  }
}

export const DOWNLOAD_SAP_REQUEST = 'DOWNLOAD_SAP_REQUEST'
export const DOWNLOAD_SAP_SUCCESS = 'DOWNLOAD_SAP_SUCCESS'
export const DOWNLOAD_SAP_FAILURE = 'DOWNLOAD_SAP_FAILURE'

export function fetchSAPValues() {
  let params = ''

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `customers/sapvalues?${params}`,
      authenticated: true,
      types: [DOWNLOAD_SAP_REQUEST, DOWNLOAD_SAP_SUCCESS, DOWNLOAD_SAP_FAILURE]
    }
  }
}