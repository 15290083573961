import createHistory from 'history/createBrowserHistory'
import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { routerMiddleware, routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import { createLogger } from 'redux-logger'

import apiMiddleware from '../middleware/api'
import authReducer from '../reducers/auth'
import contractsReducer from '../reducers/contracts'
import customersReducer from '../reducers/customers'
import incidencesReducer from '../reducers/incidences'
import itemsReducer from '../reducers/items'
import MachinesReducer from '../reducers/machines'
import salesReducer from '../reducers/sales'
import usersReducer from '../reducers/users'
import remoteActionsReducer from '../reducers/remoteactions'
import updatesReducer from '../reducers/updates'
import shipmentsReducer from '../reducers/shipments'
import auditsReducer from '../reducers/audits'
import openingsReducer from '../reducers/openings'
import shiftsReducer from '../reducers/shifts'
import promoterSalesReducer from '../reducers/promotersales'
import matrixsReducer from '../reducers/matrixs'
import arqueosReducer from '../reducers/arqueos'
import promotersReducer from '../reducers/promoters'
import modulesReducer from '../reducers/modules'
import qrsReducer from '../reducers/qrs'
import withdrawalsReducer from '../reducers/withdrawals'
import replacementsReducer from '../reducers/replacements'
import faultsReducer from '../reducers/faults'
import supportticketsReducer from '../reducers/supporttickets'
import objectivesReducer from '../reducers/objectives'
import pdasReducer from '../reducers/pdas'
import operatorsReducer from '../reducers/operators'
import calendarsReducer from '../reducers/calendars'
import rulesReducer from '../reducers/rules'
import rentalsReducer from '../reducers/rentals'
import articlesReducer from '../reducers/articles'
import arqueosbaqueiraReducer from '../reducers/arqueosbaqueira'
import testsReducer from '../reducers/tests'

import { AUTH_LOGOUT } from '../actions/auth'

export const history = createHistory()

const myRouterMiddleware = routerMiddleware(history)
const loggerMiddleware = createLogger()

const appReducer = combineReducers({
  auth: authReducer,
  contracts: contractsReducer,
  customers: customersReducer,
  incidences: incidencesReducer,
  items: itemsReducer,
  machines: MachinesReducer,
  sales: salesReducer,
  users: usersReducer,
  remoteactions: remoteActionsReducer,
  updates: updatesReducer,
  shipments: shipmentsReducer,
  audits: auditsReducer,
  shifts: shiftsReducer,
  promoterSales: promoterSalesReducer,
  matrixs: matrixsReducer,
  arqueos: arqueosReducer,
  promoters: promotersReducer,
  modules: modulesReducer,
  qrs: qrsReducer,
  withdrawals: withdrawalsReducer,
  replacements: replacementsReducer,
  faults: faultsReducer,
  supporttickets: supportticketsReducer,
  objectives: objectivesReducer,
  pdas: pdasReducer,
  openings: openingsReducer,
  operators: operatorsReducer,
  calendars: calendarsReducer,
  rules: rulesReducer,
  rentals: rentalsReducer,
  articles: articlesReducer,
  arqueosbaqueira: arqueosbaqueiraReducer,
  tests: testsReducer,
  form: formReducer,
  router: routerReducer
})

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
};

const middleware = process.env.NODE_ENV === 'production' ?
  applyMiddleware(
    thunkMiddleware,
    apiMiddleware,
    myRouterMiddleware
  ) :
  applyMiddleware(
    thunkMiddleware,
    apiMiddleware,
    myRouterMiddleware,
    loggerMiddleware
  )

export const store = createStore(rootReducer, middleware);
