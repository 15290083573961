import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchTestRentals } from '../actions/tests'

import ErrorMessage from '../components/ErrorMessage'
import RentalsList from '../components/RentalsList'
import RentalsFinishedList from '../components/RentalsFinishedList'

class TestsRentalsView extends React.Component {
  constructor(props) {
    super(props)
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-indexed: 0=Jan, 7=Aug, etc.
    // If month is less than August (i.e. 0-6), default is previous year-current year.
    const defaultSelectedYearRange = currentMonth < 7 
      ? `${currentYear - 1}-${currentYear}` 
      : `${currentYear}-${currentYear + 1}`;

    // State to track visibility of active and finished rentals
    this.state = {
      showActiveRentals: true, // Active rentals list is visible by default
      showFinishedRentals: false, // Finished rentals list is hidden by default
      showPrerentRentals: false,
      selectedYearRange: defaultSelectedYearRange // default range
    }
  }

  componentDidMount() {

    this.props.fetchTestRentals()
  }

  // Toggle visibility for active rentals
  toggleActiveRentals = () => {
    this.setState((prevState) => ({
      showActiveRentals: !prevState.showActiveRentals
    }))
  }

  // Toggle visibility for finished rentals
  toggleFinishedRentals = () => {
    this.setState((prevState) => ({
      showFinishedRentals: !prevState.showFinishedRentals
    }))
  }

  togglePrerentRentals = () => {
    this.setState((prevState) => ({
      showPrerentRentals: !prevState.showPrerentRentals
    }))
  }

  render() {
    const { rentalstest, error, loading, type } = this.props

    if (type === 'PDA') {
      return (<div><h1><span className="text-vw-light">USUARIO NO AUTORIZADO</span></h1></div>)
    }
    

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!rentalstest || loading) {
      return (<div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div>
        <div className="row mb-2 align-items-center">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-light">TEST RESERVAS</span>
              <span className="text-vw-dark"> ACTIVAS </span>
              <span className="text-vw-light">DE CLIENTE</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <button className="btn btn-primary float-right" onClick={this.toggleActiveRentals}>
              {this.state.showActiveRentals ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {this.state.showActiveRentals && <RentalsList rentals={rentalstest} />}
        <div className="row mb-2 mt-5 align-items-center">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-light">RESERVAS</span>
              <span className="text-vw-dark"> FINALIZADAS </span>
              <span className="text-vw-light">DE CLIENTE</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <button className="btn btn-primary float-right" onClick={this.toggleFinishedRentals}>
              {this.state.showFinishedRentals ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {this.state.showFinishedRentals && <RentalsFinishedList rentals={rentalstest} />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  rentalstest: state.tests.rentalstest,
  error: state.tests.error,
  loading: state.tests.loading,
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
  fetchTestRentals: bindActionCreators(fetchTestRentals, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TestsRentalsView)
