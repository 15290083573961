import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchMatrixs } from '../actions/matrixs';
import { fetchCustomerPDA } from '../actions/customers';
import { fetchRentals } from '../actions/rentals';
import { fetchMachines } from '../actions/machines'

import { Bar } from 'react-chartjs-2';

class OcupationCustomerView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      rentalsData: null,
    };
  }

  componentDidMount() {
    const { type, id, role } = this.props;

    if (type === 'PDA') {
      this.props
        .fetchCustomerPDA(id)
        .then((response) => {
          this.props.fetchMatrixs('', '', response.response.Customer, '')
            .then((response) => {
              this.props.fetchRentals()
                .then((response) => {
                  this.props.fetchMachines()
                    .then((response) => {
                      this.processRentalsData();
                    })
                })
            })
        })
        .catch((error) => {
          console.error('Error fetching customer PDA:', error);
          this.setState({ error: 'Failed to fetch customer PDA', loading: false });
        });
    } else if (role === "CUSTOMER") {
      this.props.fetchMatrixs('', '', id, '')
        .then((response) => {
          this.props.fetchRentals()
            .then((response) => {
              this.props.fetchMachines()
                .then((response) => {
                  this.processRentalsData();
                })
            })
        })
    } else {
      this.props.fetchMatrixs('', '', '', '')
        .then((response) => {
          this.props.fetchRentals()
            .then((response) => {
              this.props.fetchMachines()
                .then((response) => {
                  this.processRentalsData();
                })
            })
        })
    }
  }

  progressBar(actual, objective) {

    const percentage = Math.min((actual / objective) * 100, 100);

    let color = "bg-warning"

    if (percentage < 50) {
      color = "bg-success"
    } else if (percentage > 80) {
      color = "bg-danger"
    }

    return (
      <div className="progress-container">
        <div className={`progress-bar ${color}`} style={{ width: `${percentage}%` }}></div>
        <div className="progress-percentage">{percentage.toFixed(2)}%</div>
      </div>
    );
  }

  processRentalsData() {
    const { rentals, matrixs } = this.props;

    if (!rentals || !matrixs) return;

    const totalLockers = matrixs.reduce((sum, matrix) => {
      if (matrix.Locker && Array.isArray(matrix.Locker)) {
        return sum + matrix.Locker.length;
      }
      return sum;
    }, 0);

    // Filter out canceled rentals
    const validRentals = rentals.filter(rental => (rental.Status !== 'CANCELED' && rental.Status !== 'PRERENT' && rental.Type !== 'COUNTERINVOICE'));

    // Parse rentals data
    const rentalsByDate = {};
    validRentals.forEach((rental) => {
      const { StartDate, FinishDate, LockerMatricula } = rental;
      const startDate = moment(StartDate);
      const finishDate = moment(FinishDate);
      const days = finishDate.diff(startDate, 'days') + 1; // Include start and end date

      for (let i = 0; i < days; i++) {
        const currentDate = startDate.clone().add(i, 'days').format('YYYY-MM-DD');
        rentalsByDate[currentDate] = (rentalsByDate[currentDate] || 0) + LockerMatricula.length;
      }
    });

    // Generate data points for graph
    const startDate = moment(Object.keys(rentalsByDate).sort()[0]);
    const endDate = moment(Object.keys(rentalsByDate).sort().pop());
    const dailyData = [];

    for (let date = startDate; date <= endDate; date.add(1, 'days')) {
      const currentDate = date.format('YYYY-MM-DD');
      dailyData.push({
        date: currentDate,
        rented: rentalsByDate[currentDate] || 0,
        total: totalLockers,
      });
    }

    this.setState({ rentalsData: dailyData });
  }
  renderChart() {
    const { rentalsData } = this.state;
    const { rentals } = this.props;
  
    if (!rentalsData || !rentals) return null;
  
    // Filter rentals to exclude unwanted types and statuses
    const validRentals = rentals.filter(rental =>
      rental.Status !== 'CANCELED' &&
      rental.Status !== 'PRERENT' &&
      rental.Type.trim().toUpperCase() !== 'COUNTERINVOICE'
    );
  
    // Define the fixed order for rental types
    const fixedTypeOrder = ['SEASON', 'WHITELIST', 'RENTAL', 'COLLECT', 'OTHER'];
    const types = fixedTypeOrder.filter(type =>
      validRentals.some(rental => rental.Type.trim().toUpperCase() === type)
    );
  
    // Group rentals by type and date
    const rentalsByDateAndType = {};
    validRentals.forEach(rental => {
      const { StartDate, FinishDate, Type } = rental;
      const normalizedType = Type.trim().toUpperCase();
      const startDate = moment(StartDate);
      const finishDate = moment(FinishDate);
      const days = finishDate.diff(startDate, 'days') + 1;
  
      for (let i = 0; i < days; i++) {
        const currentDate = startDate.clone().add(i, 'days').format('YYYY-MM-DD');
        if (!rentalsByDateAndType[currentDate]) {
          rentalsByDateAndType[currentDate] = {};
        }
        rentalsByDateAndType[currentDate][normalizedType] =
          (rentalsByDateAndType[currentDate][normalizedType] || 0) + 1;
      }
    });
  
    // Generate datasets for each type in the fixed order
    const labels = rentalsData.map(data => data.date);
    const datasets = types.map(type => ({
      label: type,
      data: labels.map(label => rentalsByDateAndType[label]?.[type] || 0),
      backgroundColor: {
        RENTAL: 'rgba(255,162,0,1)',
        SEASON: 'rgba(0,85,255,1)',
        WHITELIST: 'rgba(131,131,131,1)',
        COLLECT: 'rgba(3,168,30,1)',
        OTHER: 'rgba(198,12,198,1)',
      }[type] || 'rgba(150,150,150,1)',
    }));
  
    const totalLockers = rentalsData.length > 0 ? rentalsData[0].total : 1; // Avoid division by zero
    const occupationPercentages = labels.map((date, i) => {
      const totalRented = datasets.reduce((sum, dataset) => sum + dataset.data[i], 0);
      return ((totalRented / totalLockers) * 100).toFixed(2);
    });
  
    const chartData = {
      labels,
      datasets,
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              autoSkip: false,
              callback: function (value) {
                // Show only the first day of each week
                return moment(value).isoWeekday() === 1 ? value : '';
              },
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'Lockers Rented',
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              max: totalLockers,
            },
          },
        ],
      },
      legend: {
        display: true, // Enable the legend
        position: 'top', // Position it at the top
      },
      tooltips: {
        mode: 'label',
        callbacks: {
          label: (tooltipItem, data) => {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const value = dataset.data[tooltipItem.index];
            return `${dataset.label}: ${value}`;
          },
        },
      },
    };
  
    return <Bar data={chartData} options={options} style={{ height: '700px' }} />;
  }
  
  calculateOccupation(matrixs) {
    let totalLockers = 0;
    let occupiedLockers = 0;

    matrixs.forEach((matrix) => {
      if (matrix.Locker && Array.isArray(matrix.Locker)) {
        totalLockers += matrix.Locker.length;
        occupiedLockers += matrix.Locker.filter((locker) => (locker.Status === 'RQ' || locker.Status === 'RK' || locker.Status === 'RM' || locker.Status === 'RP')).length;
      }
    });

    const occupationPercentage = totalLockers > 0 ? ((occupiedLockers / totalLockers) * 100).toFixed(2) : 0;

    return { totalLockers, occupiedLockers, occupationPercentage };
  }

  getLockerName(type) {
    switch (type) {
      case "BNS":
        return "Básica";
      case "CNS":
        return "Básica Carga";
      case "BWS":
        return "Básica XL";
      case "BNM":
        return "Grande";
      case "CNM":
        return "Grande Carga";
      case "BWM":
        return "Grande XL";
      case "CWM":
        return "Grande Carga XL";
      case "BNL":
        return "Jumbo";
      case "CNL":
        return "Jumbo Carga";
      case "BWL":
        return "Jumbo XL";
      case "CWL":
        return "Jumbo Carga XL";
      case "BNXL":
        return "Jumbo";
      case "CNXL":
        return "Jumbo Carga";
      case "BWXL":
        return "Jumbo XL";
      case "CWXL":
        return "Jumbo Carga XL";
      case "BN2XL":
        return "Jumbo";
      case "CN2XL":
        return "Jumbo Carga";
      case "BW2XL":
        return "Jumbo XL";
      case "CW2XL":
        return "Jumbo Carga XL";
      case "BN3XL":
        return "2 Equipos de esquí";
      case "CN3XL":
        return "3 Equipos de esquí";
      case "BW3XL":
        return "4 Equipos de esquí";
      default:
        return "None";
    }
  }

  renderTable(matrixs) {
    const lockerCounts = {}; // Tracks totals of each type
    const rentedCounts = {}; // Tracks "RQ" rented counts

    matrixs.forEach(matrix => {
      if (matrix.Locker && Array.isArray(matrix.Locker)) {
        matrix.Locker.forEach(locker => {
          const lockerType = `${locker.Mode}${locker.Wide}${locker.Type}`;

          // Increment total counts for this type
          lockerCounts[lockerType] = (lockerCounts[lockerType] || 0) + 1;

          // Increment rented ("RQ") counts for this type
          if (locker.Status === 'RQ' || locker.Status === 'RK' || locker.Status === 'RM' || locker.Status === 'RP') {
            rentedCounts[lockerType] = (rentedCounts[lockerType] || 0) + 1;
          }
        });
      }
    });

    // Define the reference order for sorting
    const lockerOrder = [
      "BNS", "CNS", "BWS",
      "BNM", "CNM", "BWM", "CWM",
      "BNL", "CNL", "BWL", "CWL",
      "BNXL", "CNXL", "BWXL", "CWXL",
      "BN2XL", "CN2XL", "BW2XL", "CW2XL",
      "BN3XL", "CN3XL", "BW3XL"
    ];

    const rows = Object.entries(lockerCounts)
      .map(([type, totalCount]) => {
        const rentedCount = rentedCounts[type] || 0; // Default to 0 if no "RQ" rentals for this type
        return {
          type,
          lockerName: this.getLockerName(type),
          rentedCount,
          totalCount,
          percentage: ((rentedCount / totalCount) * 100).toFixed(2)
        };
      })
      .sort((a, b) => lockerOrder.indexOf(a.type) - lockerOrder.indexOf(b.type)) // Sort based on lockerOrder
      .map(({ type, lockerName, rentedCount, totalCount, percentage }) => (
        <tr key={type}>
          <td>{lockerName}</td>
          <td>{rentedCount}</td>
          <td>{totalCount}</td>
          <td>{this.progressBar(rentedCount, totalCount)}</td>
        </tr>
      ));

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Tipo de taquilla</th>
              <th scope="col">Alquiladas</th>
              <th scope="col">Totales</th>
              <th scope="col">Porcentaje</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }

  getZoneName(zone) {

    switch (Number(zone)) {
      case 0:
        return 'GENERAL';
      case 1:
        return 'BAQUEIRA';
      case 2:
        return 'BERET';
      case 3:
        return 'BONAIGUA';
      case 4:
        return 'RUDA';
      case 5:
        return 'BOSQUE';
      default:
        return 'NONE';
    }
  }

  renderZonesTable(matrixs, machines) {
    const { category } = this.props;

    if (!machines || !matrixs) return null;

    // Map machines by serial for quick lookup
    const machineMap = machines.reduce((acc, machine) => {
      acc[machine.Serial] = machine;
      return acc;
    }, {});

    // Group matrixes by zones
    const zonesData = {};
    matrixs.forEach(matrix => {
      const machine = machineMap[matrix.Serial];
      if (machine && machine.Contract) {
        const zone = machine.Contract.Zone;

        if (!zonesData[zone]) {
          zonesData[zone] = { totalLockers: 0, rentedLockers: 0 };
        }

        if (matrix.Locker && Array.isArray(matrix.Locker)) {
          const zoneData = zonesData[zone];

          matrix.Locker.forEach(locker => {
            zoneData.totalLockers += 1;
            if (locker.Status === 'RQ' || locker.Status === 'RK' || locker.Status === 'RM' || locker.Status === 'RP') {
              zoneData.rentedLockers += 1;
            }
          });
        }
      }
    });

    // Convert zone data to table rows
    const rows = Object.entries(zonesData)
      .map(([zone, data]) => {
        const { rentedLockers, totalLockers } = data;
        const percentage = ((rentedLockers / totalLockers) * 100).toFixed(2);

        return (
          <tr key={zone}>
            <td>{category === 'BAQUEIRA' ? this.getZoneName(zone) : zone}</td>
            <td>{rentedLockers}</td>
            <td>{totalLockers}</td>
            <td>{this.progressBar(rentedLockers, totalLockers)}</td>
          </tr>
        );
      });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Zona</th>
              <th scope="col">Alquiladas</th>
              <th scope="col">Totales</th>
              <th scope="col">Porcentaje</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }

  render() {
    const { matrixs, loading, error, rentals, machines, category } = this.props;

    if (loading || !matrixs || !rentals || !machines) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Loading..." />
        </div>
      );
    }

    if (error) {
      return <div className="alert alert-danger">{error}</div>;
    }

    const { totalLockers, occupiedLockers, occupationPercentage } = this.calculateOccupation(matrixs);

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">OCUPACIÓN </span>
              <span className="text-vw-light">ACUMULADA</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <div className="p-3 mb-3 bg-white rounded text-vw-dark box-shadow">
          <div class="row" style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', width: 'fit-content' }}>
            <h5 className="text-vw-light" style={{ marginRight: '2vw' }}>OCUPACIÓN:</h5>
            <h5 style={{ marginRight: '2vw' }}>{occupationPercentage} %</h5>
            <h5 className="text-vw-light" style={{ marginRight: '2vw' }}>ALQUILADAS:</h5>
            <h5 style={{ marginRight: '2vw' }}>{occupiedLockers}</h5>
            <h5 className="text-vw-light" style={{ marginRight: '2vw' }}>TOTAL:</h5>
            <h5 style={{ marginRight: '2vw' }}>{totalLockers}</h5>
          </div>
        </div>
        <div className="row mb-2 mt-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">OCUPACIÓN </span>
              <span className="text-vw-light">POR DÍAS</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <div id="canvas-container">
          {this.renderChart()}
        </div>
        <div className="row mb-2 mt-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">OCUPACIÓN </span>
              <span className="text-vw-light">POR ZONA</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {this.renderZonesTable(matrixs, machines)}
        <div className="row mb-2 mt-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">OCUPACIÓN </span>
              <span className="text-vw-light">POR TIPO</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {this.renderTable(matrixs)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  matrixs: state.matrixs.matrixs,
  loading: state.matrixs.loading,
  error: state.matrixs.error,
  role: state.auth.role,
  id: state.auth.id,
  type: state.auth.type,
  category: state.auth.category,
  rentals: state.rentals.rentals,
  machines: state.machines.machines,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch),
  fetchCustomerPDA: bindActionCreators(fetchCustomerPDA, dispatch),
  fetchRentals: bindActionCreators(fetchRentals, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OcupationCustomerView);
