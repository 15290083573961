import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchRentals } from '../actions/rentals'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import RentalsList from '../components/RentalsList'
import RentalsFinishedList from '../components/RentalsFinishedList'
import RentalsPrerentList from '../components/RentalsPrerentList'

class RentalsView extends React.Component {
  constructor(props) {
    super(props)
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-indexed: 0=Jan, 7=Aug, etc.
    // If month is less than August (i.e. 0-6), default is previous year-current year.
    const defaultSelectedYearRange = currentMonth < 7 
      ? `${currentYear - 1}-${currentYear}` 
      : `${currentYear}-${currentYear + 1}`;

    // State to track visibility of active and finished rentals
    this.state = {
      showActiveRentals: true, // Active rentals list is visible by default
      showFinishedRentals: false, // Finished rentals list is hidden by default
      showPrerentRentals: false,
      selectedYearRange: defaultSelectedYearRange // default range
    }
  }

  componentDidMount() {
    // On mount, fetch rentals using the default year range.
    const { selectedYearRange } = this.state
    const { startDate, endDate } = this.computeYearRangeDates(selectedYearRange)
    this.props.fetchRentals(undefined, undefined, startDate, endDate)
  }

  // Helper to generate an array of year ranges starting from 2024 up to the current year.
  generateYearRangeOptions = () => {
    const currentYear = new Date().getFullYear()
    const options = []
    // For each year from 2024 to currentYear, create a range like "YYYY-YYYY+1"
    for (let year = 2024; year <= currentYear; year++) {
      options.push(`${year}-${year + 1}`)
    }
    return options
  }

  computeYearRangeDates = (yearRange) => {
    // yearRange is in the format "YYYY-YYYY"
    const [startYear, endYear] = yearRange.split('-').map(Number)
    // Fixed: start date August 1st and end date July 31st
    const startDate = `${startYear}-08-01`
    const endDate = `${endYear}-07-31`
    return { startDate, endDate }
  }

  handleYearRangeChange = (e) => {
    const selectedYearRange = e.target.value
    this.setState({ selectedYearRange })
    const { startDate, endDate } = this.computeYearRangeDates(selectedYearRange)
    // Launch a new fetch with the new start and end dates.
    this.props.fetchRentals(undefined, undefined, startDate, endDate)
  }

  // Toggle visibility for active rentals
  toggleActiveRentals = () => {
    this.setState((prevState) => ({
      showActiveRentals: !prevState.showActiveRentals
    }))
  }

  // Toggle visibility for finished rentals
  toggleFinishedRentals = () => {
    this.setState((prevState) => ({
      showFinishedRentals: !prevState.showFinishedRentals
    }))
  }

  togglePrerentRentals = () => {
    this.setState((prevState) => ({
      showPrerentRentals: !prevState.showPrerentRentals
    }))
  }

  render() {
    const { rentals, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!rentals || loading) {
      return (<div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    // Calculate the total of CANCELED rentals with PRERENT
    const totalCanceledPrerent = rentals.filter(rental => rental.Status === 'CANCELED' && rental.Name === 'PRERENT').length;

    const totalRentals = rentals.length;

    const percentageCanceledPrerent = totalRentals > 0
      ? ((totalCanceledPrerent / totalRentals) * 100).toFixed(2)
      : 0;

    return (
      <div>
        <div className="row mb-2 align-items-center">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-light">RESERVAS</span>
              <span className="text-vw-dark"> ACTIVAS </span>
              <span className="text-vw-light">DE CLIENTE</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-2 col-md-2">
          <select
              value={this.state.selectedYearRange}
              onChange={this.handleYearRangeChange}
              className="form-control"
            >
              {this.generateYearRangeOptions().map(range => (
                <option key={range} value={range}>Temporada {range}</option>
              ))}
            </select>
          </div>
          <div className="col-xs-12 col-sm-2 col-md-2">
            <button className="btn btn-primary float-right" onClick={this.toggleActiveRentals}>
              {this.state.showActiveRentals ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {this.state.showActiveRentals && <RentalsList rentals={rentals} />}
        <div className="row mb-2 mt-5 align-items-center">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-light">RESERVAS</span>
              <span className="text-vw-dark"> FINALIZADAS </span>
              <span className="text-vw-light">DE CLIENTE</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <button className="btn btn-primary float-right" onClick={this.toggleFinishedRentals}>
              {this.state.showFinishedRentals ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {this.state.showFinishedRentals && <RentalsFinishedList rentals={rentals} />}
        <div className="row mb-2 mt-5 align-items-center">
          <div className="col-xs-12 col-sm-10 col-md-10">
            <h1>
              <span className="text-vw-light">RESERVAS</span>
              <span className="text-vw-dark"> CANCELADAS </span>
              <span className="text-vw-light">EN CARRITO </span>
              <span className="text-vw-light">{totalCanceledPrerent} ({percentageCanceledPrerent}%)</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-2 col-md-2">
            <button className="btn btn-primary float-right" onClick={this.togglePrerentRentals}>
              {this.state.showPrerentRentals ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {this.state.showPrerentRentals && <RentalsPrerentList rentals={rentals} />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  rentals: state.rentals.rentals,
  error: state.rentals.error,
  loading: state.rentals.loading,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchRentals: bindActionCreators(fetchRentals, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RentalsView)
