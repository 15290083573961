import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchRentals } from '../actions/rentals'

import ErrorMessage from '../components/ErrorMessage'

class RentalsConflictsView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showConflicts: true // Show the conflicts list by default
    }
  }

  componentDidMount() {
    this.props.fetchRentals()
  }

  getZoneName(zone) {
    switch (zone) {
      case 0:
        return 'GENERAL'
      case 1:
        return 'BAQUEIRA'
      case 2:
        return 'BERET'
      case 3:
        return 'BONAIGUA'
      case 4:
        return 'RUDA'
      case 5:
        return 'BOSQUE'
      default:
        return 'NONE'
    }
  }

  // Toggle visibility for the conflicts list
  toggleConflicts = () => {
    this.setState((prevState) => ({
      showConflicts: !prevState.showConflicts
    }))
  }

  // Helper to check if two rentals have overlapping dates
  isOverlapping = (rentalA, rentalB) => {
    // Normalize a date string to a date with time set to 00:00:00
    const normalizeDate = (dateString) => {
      const d = new Date(dateString)
      d.setHours(0, 0, 0, 0)
      return d
    }
  
    const startA = normalizeDate(rentalA.StartDate)
    const finishA = normalizeDate(rentalA.FinishDate)
    const startB = normalizeDate(rentalB.StartDate)
    const finishB = normalizeDate(rentalB.FinishDate)
  
    // With normalized dates, if one rental starts on the same day the other ends,
    // they are considered overlapping.
    return startA <= finishB && startB <= finishA
  }

  render() {
    const { rentals, error, loading } = this.props

    if (error) {
      return <ErrorMessage message={error.message} />
    }

    if (!rentals || loading) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      )
    }

    const today = new Date()

    // Filter future rentals and exclude those with status PRERENT, CANCELED, or COMPLETE
    const futureRentals = rentals.filter((rental) => {
      const startDate = new Date(rental.StartDate)
      // return (
      //   startDate >= today &&
      //   rental.Status !== 'PRERENT' &&
      //   rental.Status !== 'CANCELED' &&
      //   rental.Status !== 'COMPLETE'
      // )

      return (
        rental.Status !== 'PRERENT' &&
        rental.Status !== 'CANCELED' &&
        rental.Status !== 'COMPLETE'
      )
    })

    // Build a map keyed by zone, machine _id, and locker number (e.g., "1-66b1d6562da78d27d33ba581-25")
    const lockerMachineZoneMap = {}
    futureRentals.forEach((rental) => {
      const zone = rental.Machine && rental.Machine.Contract ? rental.Machine.Contract.Zone : null
      const machineId = rental.Machine && rental.Machine._id ? rental.Machine._id : null
      if (!zone || !machineId || !Array.isArray(rental.LockerMatricula)) {
        return
      }
      rental.LockerMatricula.forEach((locker) => {
        // Create a combined key with zone, machine, and locker number
        const key = `${zone}-${machineId}-${locker}`
        if (!lockerMachineZoneMap[key]) {
          lockerMachineZoneMap[key] = { zone, machineId, locker, rentals: [] }
        }
        lockerMachineZoneMap[key].rentals.push(rental)
      })
    })

    // For each group, check for overlapping dates among rentals
    const conflictGroups = []
    Object.entries(lockerMachineZoneMap).forEach(([key, group]) => {
      const { rentals: groupRentals, zone, locker, machineId } = group
      if (groupRentals.length < 2) return

      // Use a Set to track rental _ids that have at least one conflict
      const conflictRentalIds = new Set()
      for (let i = 0; i < groupRentals.length; i++) {
        for (let j = i + 1; j < groupRentals.length; j++) {
          if (this.isOverlapping(groupRentals[i], groupRentals[j])) {
            conflictRentalIds.add(groupRentals[i]._id)
            conflictRentalIds.add(groupRentals[j]._id)
          }
        }
      }

      // Only include the group if there's at least one conflict
      if (conflictRentalIds.size > 0) {
        const conflictingRentals = groupRentals.filter(rental =>
          conflictRentalIds.has(rental._id)
        )
        conflictGroups.push({ key, zone, machineId, locker, rentals: conflictingRentals })
      }
    })

    const totalConflicts = conflictGroups.length

    return (
      <div>
        <div className="row mb-2 align-items-center">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-light">CONFLICTOS FUTUROS</span>{' '}
              <span className="text-vw-dark">({totalConflicts})</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <button className="btn btn-primary float-right" onClick={this.toggleConflicts}>
              {this.state.showConflicts ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {this.state.showConflicts && (
          <div>
            {totalConflicts === 0 ? (
              <p>No hay conflictos futuros encontrados.</p>
            ) : (
              conflictGroups.map((group, index) => (
                <div key={index} className="conflict-item mb-4">
                  <h3>
                    <strong>Zona:</strong> {this.getZoneName(group.zone)} |{' '}
                    <strong>Locker:</strong> {group.locker}
                  </h3>
                  {group.rentals.map((rental, idx) => (
                    <div key={idx} className="rental-conflict p-2 border rounded mb-2">
                      <p>
                        <strong>Reserva:</strong> {rental.Code}
                      </p>
                      <p>
                        <strong>Cliente:</strong> {rental.Name}
                      </p>
                      <p>
                        <strong>Fecha Pago:</strong>{' '}
                        {new Date(rental.TimeStamp).toLocaleString()}
                      </p>
                      <p>
                        <strong>Fecha Inicio:</strong>{' '}
                        {new Date(rental.StartDate).toLocaleString()}
                      </p>
                      <p>
                        <strong>Fecha Fin:</strong>{' '}
                        {new Date(rental.FinishDate).toLocaleString()}
                      </p>
                      <p>
                        <strong>Status:</strong> {rental.Status}
                      </p>
                    </div>
                  ))}
                  <hr className="bg-vw-light" />
                </div>
              ))
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  rentals: state.rentals.rentals,
  error: state.rentals.error,
  loading: state.rentals.loading,
  form: state.form
})

const mapDispatchToProps = (dispatch) => ({
  fetchRentals: bindActionCreators(fetchRentals, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RentalsConflictsView)
