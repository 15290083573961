import React from 'react';
import { connect } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import HTMLFlipBook from 'react-pageflip';

// Configure the pdfjs worker (using a locally installed version or serving from public)
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

class ManualDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      scale: 1.0, // initial zoom level (100%)
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handleZoomIn = () => {
    this.setState((prevState) => ({ scale: prevState.scale + 0.2 }));
  };

  handleZoomOut = () => {
    this.setState((prevState) => ({ scale: Math.max(prevState.scale - 0.2, 0.5) }));
  };

  render() {
    const { id } = this.props.match.params;
    const pdfUrl = `/manuals/${id}.pdf`;
    const { numPages, scale } = this.state;

    // Generate an array of pages using the current scale
    const pages = [];
    if (numPages) {
      for (let i = 1; i <= numPages; i++) {
        pages.push(
          <div key={i}>
            <Page pageNumber={i} scale={scale} />
          </div>
        );
      }
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-10 col-md-10">
            <h1 className="text-vw-dark">MANUAL</h1>
          </div>
          <div className="col-xs-12 col-sm-2 col-md-2 align-items-center d-flex justify-content-center">
            <div className="col d-flex justify-content-center">
              <button
                className="btn bg-info text-white"
                onClick={() => window.open(pdfUrl, '_blank')}
              >
                <i className="fas fa-search mr-1"></i>Zoom
              </button>
            </div>
          </div>
        </div>
        <hr className="bg-vw-light" />
        {/* Center the flipbook container */}
        <div className='manual-pdf'>
          <Document file={pdfUrl} onLoadSuccess={this.onDocumentLoadSuccess}>
            {numPages && (
              <HTMLFlipBook
                width={600 * scale}
                height={850 * scale}
                showCover={false} // ensures two pages are shown instead of cover mode
              >
                {pages}
              </HTMLFlipBook>
            )}
          </Document>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.audits.error,
  loading: state.audits.loading,
  form: state.form,
});

export default connect(mapStateToProps)(ManualDetailsView);
