import React from 'react'
import { history } from '../store'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchModules, fetchModuleDownloadCSV } from '../actions/modules'
import { fetchMachines } from '../actions/machines'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import Box from '../components/Box'
import classnames from 'classnames';
import PropertyLabel from '../components/PropertyLabel'
import AssignedModuleList from '../components/AssignedModuleList'

import ModuleList from '../components/ModuleList'
import ModuleErrorList from '../components/ModuleErrorList'
import ErrorMessage from '../components/ErrorMessage'

class ModulesView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: this.props.type === 'PRODUCTION' ? '2' : '1'
    }

    this.handleModuleDownloadCSV = this.handleModuleDownloadCSV.bind(this)
  }

  componentDidMount() {
    this.props.fetchModules()
    this.props.fetchMachines()
  }

  goTo(Code, e) {
    history.push(`/modules/${Code}`)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  printDuplicatedSN() {
    const { modules, machines } = this.props;

    if (!modules || !machines) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>);
    }

    // Create an object to store the counts of each SN
    const snCounts = {};

    // Iterate through machines and then through each module
    machines.forEach((machine) => {

      if (machine.Serial !== "VLK00000" && machine.Serial !== "VLK00001") {

        let index = 0;

        machine.Modules.forEach((mod) => {
          // Find the corresponding module in 'modules' that fits mod.Code
          const correspondingModule = modules.find(module => module.Code === mod.serialNumber);

          if (correspondingModule) {

            // Iterate through RegisterLockers of the corresponding module
            correspondingModule.RegisterLockers.forEach((lock) => {
              const { SN } = lock;

              if (!/^0+$/.test(SN)) {
                if (snCounts[SN]) {
                  snCounts[SN].count += 1;
                  snCounts[SN].module.push(mod.serialNumber);
                  snCounts[SN].machine.push(machine.Serial);
                  snCounts[SN].position.push(`${machine.FirstIndex + index}`);
                } else {
                  snCounts[SN] = {
                    count: 1,
                    module: [mod.serialNumber],
                    machine: [machine.Serial],
                    position: [`${machine.FirstIndex + index}`],
                  };
                }
              }

              index++;
            });
          }
        });
      }
    });

    // Find the maximum number of modules for a single SN
    const maxModules = Object.values(snCounts).reduce((max, item) => Math.max(max, item.module.length), 0);

    // Filter the SNs that have duplicates
    const duplicates = Object.entries(snCounts).filter(([SN, data]) => data.count > 1);

    return (
      <div className="table-responsive">
        {duplicates.length > 0 ? (
          <table className="table table-hover table-clickable">
            <thead className="thead-dark">
              <tr>
                <th>DUPLICATED SN</th>
                {[...Array(maxModules)].map((_, index) => <th key={index} colSpan="3">Module {index + 1}</th>)}
              </tr>
            </thead>
            <tbody>
              {duplicates.map(([SN, data]) => (
                <tr key={SN}>
                  <td>{SN}</td>
                  {[...Array(maxModules)].flatMap((_, index) => [
                    <td style={{ borderLeft: '2px solid #2dccd3' }} key={index}>Index: {data.position[index] || ''}</td>,
                    <td key={index}>Kiosk: {data.machine[index] || ''}</td>,
                    <td style={{ borderRight: '2px solid #2dccd3' }} key={index}>Module: {data.module[index] || ''}</td>
                  ])}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No duplicate SNs found.</p>
        )}
      </div>
    );
  }

  printModulesByCustomer() {

    const { machines } = this.props;

    if (!machines) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    // Group modules by customer and calculate totals
    const modulesByCustomer = {};
    machines.forEach(machine => {
      const customerName = machine.Customer?.Fullname || 'None';

      if (!modulesByCustomer[customerName]) {
        modulesByCustomer[customerName] = {
          modules: [],
          totals: {} // Track totals for each module type
        };
      }

      // Concatenate modules for each machine
      modulesByCustomer[customerName].modules = modulesByCustomer[customerName].modules.concat(machine.Modules);

      // Count module types
      machine.Modules.forEach(module => {
        const moduleType = module.serialNumber.substring(0, 5); // Assuming the module type is the first 5 characters
        modulesByCustomer[customerName].totals[moduleType] = (modulesByCustomer[customerName].totals[moduleType] || 0) + 1;
      });
    });

    // Render the table for each customer
    return (
      <div>
        {Object.keys(modulesByCustomer).sort().map(customerName => (
          <div key={customerName} className="row mb-2">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="table-responsive">
                <table className="table table-sm table-stripped table-hover table-clickable">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">{customerName}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modulesByCustomer[customerName].modules.map(module => (
                      <tr key={module.serialNumber}
                        onClick={e => this.goTo(module.serialNumber, e)}>
                        <th scope="row">{module.serialNumber}</th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div className="table-responsive">
                <table className="table table-sm table-stripped">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Module Totals {customerName}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(modulesByCustomer[customerName].totals).map(([moduleType, count]) => (
                      <tr>
                        <th scope="row">{`${moduleType}: ${count} unit${count !== 1 ? 's' : ''}`}</th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  handleModuleDownloadCSV() {
    const { fetchModuleDownloadCSV } = this.props

    fetchModuleDownloadCSV();
  }

  render() {
    const { role, type, modules, error } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!modules) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">PARQUE DE </span>
              <span className="text-vw-light">MÓDULOS</span>
            </h1>
          </div>
          {(role === 'ADMIN' && (type === 'GOD' || type === 'ROOT' || type === 'PRODUCTION' || type === 'LIMITED')) &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-end">
                <button type="button" className="btn bg-vw-excel text-white mr-2" onClick={this.handleModuleDownloadCSV}>
                  <i className="fas fa-file-excel mr-1"></i>Descargar Listado
                </button>
                <Link to="/modules/add">
                  <button type="button" className="btn bg-vw-light text-white">
                    <i className="fas fa-plus mr-1"></i>Nuevo Módulo
                  </button>
                </Link>
              </div>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <Box title="Tipos de módulo" icon="arrows-alt-h">
          <div>
            <Nav tabs>
              {role === 'ADMIN' && type !== 'PRODUCTION' &&
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                  >
                    POR CLIENTE
                  </NavLink>
                </NavItem>
              }
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}
                >
                  VLK04
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}
                >
                  VLK06
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '13' })}
                  onClick={() => { this.toggle('13'); }}
                >
                  VLK08
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '12' })}
                  onClick={() => { this.toggle('12'); }}
                >
                  VLM01
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '4' })}
                  onClick={() => { this.toggle('4'); }}
                >
                  VLM02
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '5' })}
                  onClick={() => { this.toggle('5'); }}
                >
                  VLM04
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '6' })}
                  onClick={() => { this.toggle('6'); }}
                >
                  VLM06
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '7' })}
                  onClick={() => { this.toggle('7'); }}
                >
                  VLM08
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '8' })}
                  onClick={() => { this.toggle('8'); }}
                >
                  VLM10
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '9' })}
                  onClick={() => { this.toggle('9'); }}
                >
                  VLM12
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === '10' })}
                  onClick={() => { this.toggle('10'); }}
                >
                  ERROR
                </NavLink>
              </NavItem>
              {role === 'ADMIN' && type !== 'PRODUCTION' &&
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '11' })}
                    onClick={() => { this.toggle('11'); }}
                  >
                    DUPLICATED SN
                  </NavLink>
                </NavItem>
              }
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {this.printModulesByCustomer()}
              </TabPane>
              <TabPane tabId="2">
                <ModuleList modules={modules} prefix='VLK04' />
              </TabPane>
              <TabPane tabId="3">
                <ModuleList modules={modules} prefix='VLK06' />
              </TabPane>
              <TabPane tabId="13">
                <ModuleList modules={modules} prefix='VLK08' />
              </TabPane>
              <TabPane tabId="12">
                <ModuleList modules={modules} prefix='VLM01' />
              </TabPane>
              <TabPane tabId="4">
                <ModuleList modules={modules} prefix='VLM02' />
              </TabPane>
              <TabPane tabId="5">
                <ModuleList modules={modules} prefix='VLM04' />
              </TabPane>
              <TabPane tabId="6">
                <ModuleList modules={modules} prefix='VLM06' />
              </TabPane>
              <TabPane tabId="7">
                <ModuleList modules={modules} prefix='VLM08' />
              </TabPane>
              <TabPane tabId="8">
                <ModuleList modules={modules} prefix='VLM10' />
              </TabPane>
              <TabPane tabId="9">
                <ModuleList modules={modules} prefix='VLM12' />
              </TabPane>
              <TabPane tabId="10">
                <ModuleErrorList modules={modules} />
              </TabPane>
              <TabPane tabId="11">
                {this.printDuplicatedSN()}
              </TabPane>
            </TabContent>
          </div>
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  modules: state.modules.modules,
  machines: state.machines.machines,
  error: state.modules.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchModules: bindActionCreators(fetchModules, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchModuleDownloadCSV: bindActionCreators(fetchModuleDownloadCSV, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModulesView)
