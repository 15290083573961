import React from 'react';
import { connect } from 'react-redux';
import { history } from '../store';

class ManualsView extends React.Component {
  constructor(props) {
    super(props);
    // Define a list of manual names (without language or extension)
    // You can update this list as needed.
    this.manuals = [
      'Cash',
      'Incidences',
      'Management',
      'TechnicalRequirements',
      'TroubleShooting',
      'User',
      'TechnicalSpecs',
    ];
  }

  // Redirect to the manual pdf
  goTo(id, e) {
    history.push(`/manuals/${id}`);
  }

  render() {
    // Create arrays for Spanish and English manuals
    const spanishManuals = this.manuals.map((name) => ({
      label: `${name} (ES)`,
      file: `Manual${name}_es`,
    }));

    const englishManuals = this.manuals.map((name) => ({
      label: `${name} (EN)`,
      file: `Manual${name}_en`,
    }));

    return (
      <div>
        <div className="row mb-4">
          <div className="col-12">
            <h1 className="text-vw-dark">MANUALES</h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <div className="row">
          {/* Spanish Manuals Column */}
          <div className="col-md-5">
            <h2 className="text-vw-dark">Español</h2>
            {spanishManuals.map((manual) => (
              <button
                key={manual.file}
                className="btn btn-info mb-2 w-100"
                onClick={() => this.goTo(manual.file)}
              >
                {manual.label}
              </button>
            ))}
          </div>
          <div className="col-md-2">

          </div>
          {/* English Manuals Column */}
          <div className="col-md-5">
            <h2 className="text-vw-dark">English</h2>
            {englishManuals.map((manual) => (
              <button
                key={manual.file}
                className="btn btn-info mb-2 w-100"
                onClick={() => this.goTo(manual.file)}
              >
                {manual.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.audits.error,
  loading: state.audits.loading,
  form: state.form,
});

export default connect(mapStateToProps)(ManualsView);
