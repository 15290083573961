import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'

import { fetchRemoteActions, addRemoteAction } from '../actions/remoteactions'
import { renderInputField, renderSelectField } from '../utils/forms'

class RemoteActionSingleAddForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      actionCompleted: false,
      errorMessage: '',
    }

    this.submit = this.submit.bind(this)
  }

  async submit(props) {

    if (this.props.machine.Status !== 'OPERATIVE' && this.props.machine.Status !== 'LOCKERS_FULL' && this.props.machine.Status !== 'SLEEP') {
      alert("Acción bloqueada! La máquina no está online.");
      return 0;
    }

    this.setState({ loading: true, actionCompleted: false, errorMessage: '' });

    // Insert machine id to the query
    props.machine = this.props.machine._id
    props.executor = this.props.fullname

    try {
      const response = await this.props.addRemoteAction(props);

      if (response.error) {
        throw new SubmissionError({
          _error: response.error.message,
          ...response.error.fields
        });
      }

      // Wait for fetchRemoteActions to complete
      await this.waitForRemoteActionCompletion(response.response._id);

      this.setState({ loading: false, actionCompleted: true });
      return response;

    } catch (error) {
      this.setState({ loading: false, errorMessage: error.message || 'Error processing action' });
      throw error;
    }
  }

  waitForRemoteActionCompletion(actionId) {
    return new Promise((resolve, reject) => {
      const checkStatus = async () => {

        if (this.state.cancelled) {
          reject(new Error('Action cancelled by user'));
          return;
        }

        try {
          const response = await this.props.fetchRemoteActions(this.props.machine.Serial);
          const action = response.response.find(action => action._id === actionId);

          if (action && (action.status === 'COMPLETE' || action.status === 'ERROR')) {
            resolve(action);
          } else {
            setTimeout(checkStatus, 1000); // Check status again after 1 second
          }
        } catch (error) {
          reject(error);
        }
      };

      checkStatus();
    });
  }

  handleCancel() {
    this.setState({ loading: false, cancelled: true });
  }

  render() {
    const { error, pristine, submitting, handleSubmit, machine, category } = this.props
    const { loading, actionCompleted, errorMessage } = this.state

    if (loading) {
      return (
        <div className="justify-content-center">
          <span>Ejecutando acción...</span>
          <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
        </div>
      );
    }

    if (actionCompleted) {
      return (
        <div className="flex justify-content-center">
          <div className="alert alert-success">Proceso completado</div>
          <div className="alert alert-success">Actualiza la página para ver los cambios en la matriz</div>
        </div>
      );
    }

    return (
      <div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="row">
              <div className="col col-xs-12 col-md-12 col-lg-6">
                <button className="btn mt-2 mb-2 d-block w-100 bg-vw-excel text-white" onClick={() => {
                  this.props.dispatch(change('RemoteActionSingleAddForm', 'code', 'SINGLE_DOOR_OPEN'));
                  this.props.dispatch(change('RemoteActionSingleAddForm', 'arguments', `${this.props.lockerMatricula}`));
                }}>
                  <i className="fas fa-door-open d-block ml-auto mr-auto mb-2"></i>ABRIR
                </button>
              </div>
              <div className="col col-xs-12 col-md-12 col-lg-6">
                <button
                  className="btn mt-2 mb-2 d-block w-100 bg-vw-red text-white"
                  onClick={() => {
                    this.props.dispatch(change('RemoteActionSingleAddForm', 'code', 'SINGLE_UNRENT'));
                    this.props.dispatch(change('RemoteActionSingleAddForm', 'arguments', `${this.props.lockerMatricula}`));
                  }}
                  disabled={category === 'BAQUEIRA'}
                >
                  <i className="fas fa-bluetooth d-block ml-auto mr-auto mb-2"></i>DESALQUILAR
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col col-xs-12 col-md-12 col-lg-6">
                <button
                  className="btn mt-2 mb-2 d-block w-100 bg-vw-purple text-white"
                  onClick={() => {
                    this.props.dispatch(change('RemoteActionSingleAddForm', 'code', 'SINGLE_BLOCKLATER'));
                    this.props.dispatch(change('RemoteActionSingleAddForm', 'arguments', `${this.props.lockerMatricula}`));
                  }}
                  disabled={category === 'BAQUEIRA'}
                >
                  <i className="fas fa-qrcode d-block ml-auto mr-auto mb-2"></i>RESERVA QR/PDA
                </button>
              </div>
              <div className="col col-xs-12 col-md-12 col-lg-6">
                <button
                  className="btn mt-2 mb-2 d-block w-100 bg-vw-yellow text-black"
                  onClick={() => {
                    this.props.dispatch(change('RemoteActionSingleAddForm', 'code', 'SINGLE_RESET_PIN'));
                    this.props.dispatch(change('RemoteActionSingleAddForm', 'arguments', `${this.props.lockerMatricula}`));
                  }}
                  disabled={true}
                >
                  <i className="fas fa-lock d-block ml-auto mr-auto mb-2"></i>CAMBIAR PIN
                </button>
              </div>
            </div>
            <div className="flex justify-content-center mt-5">Formulario</div>
            <form onSubmit={handleSubmit(this.submit)} className="mb-3 mt-3">
              <div className="align-items-center justify-content-center">
                <Field name="code" component={renderSelectField}>
                  <option value="">Selecciona una acción...</option>
                  <option
                    value="SINGLE_DOOR_OPEN"
                    disabled={category === 'BAQUEIRA'} // Dynamically disable individual option
                  >
                    Abrir
                  </option>
                  <option
                    value="SINGLE_UNRENT"
                    disabled={category === 'BAQUEIRA'} // Dynamically disable individual option
                  >
                    Desalquilar
                  </option>
                  <option
                    value="SINGLE_BLOCKLATER"
                    disabled={category === 'BAQUEIRA'} // Dynamically disable individual option
                  >
                    Reserva QR/PDA
                  </option>
                  <option
                    value="SINGLE_RESET_PIN"
                    disabled={category === 'BAQUEIRA'} // Dynamically disable individual option
                  >
                    Cambiar Pin
                  </option>
                </Field>
                <Field name="arguments"
                  type="text"
                  component={renderInputField}
                  placeholder="Argumentos" />
              </div>
              <div className="form-row align-items-center flex justify-content-center">
                <div className="col-md-12 align-items-center flex justify-content-center">
                  <button className="btn bg-vw-light text-white mr-1" type="submit"
                    disabled={pristine || submitting}>
                    <i className="fas fa-bolt mr-1"></i>Confirmar
                  </button>
                </div>
              </div>
              <div className="form-row">
                {error && <div className="alert alert-danger">{error}</div>}
              </div>
            </form>
          </div>
        </div>
      </div >
    )
  }
}

RemoteActionSingleAddForm = reduxForm({
  form: 'RemoteActionSingleAddForm',
})(RemoteActionSingleAddForm)

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
  category: state.auth.category,
})

const mapDispatchToProps = dispatch => ({
  addRemoteAction: bindActionCreators(addRemoteAction, dispatch),
  fetchRemoteActions: bindActionCreators(fetchRemoteActions, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoteActionSingleAddForm)

