import { CALL_API } from '../middleware/api'

export const TEST_RENTALS_REQUEST = 'TEST_RENTALS_REQUEST'
export const TEST_RENTALS_SUCCESS = 'TEST_RENTALS_SUCCESS'
export const TEST_RENTALS_FAILURE = 'TEST_RENTALS_FAILURE'

export function fetchTestRentals() {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: '/tests/rentals',
      authenticated: true,
      types: [TEST_RENTALS_REQUEST, TEST_RENTALS_SUCCESS, TEST_RENTALS_FAILURE]
    }
  }
}