import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Box from '../components/Box'

import SalesFilterForm from '../forms/SalesFilterForm'
import { fetchSales } from '../actions/sales'
import { fetchMachines } from '../actions/machines';

class SalesConflictsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conflictCount: 0,
      duplicateSales: [], // Store sales with duplicated RedsysCode
    };
  }

  componentDidMount() {
    const today = new Date().toISOString().substring(0, 10);

    this.props.fetchSales('', '', today, today, '', '', '', '')
      .then((response) => {

      })
      .catch((error) => {
        console.error('Error fetching sales data:', error);

      });

    this.props.fetchMachines()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sales !== this.props.sales) {
      this.calculateConflicts();
    }
  }

  calculateConflicts() {
    const { sales } = this.props;
    if (!sales || sales.length === 0) {
      this.setState({ conflictCount: 0, duplicateSales: [] });
      return;
    }

    const redsysMap = sales.reduce((acc, sale) => {
      const code = sale.RedsysCode;
      if (code) {
        if (!acc[code]) {
          acc[code] = [];
        }
        acc[code].push(sale);
      }
      return acc;
    }, {});

    // Filter sales with duplicates
    const duplicateSales = Object.values(redsysMap).filter((list) => list.length > 1).flat();
    const conflictCount = Object.keys(redsysMap).filter((code) => redsysMap[code].length > 1).length;

    this.setState({ conflictCount, duplicateSales });
  }

  renderDuplicateSales() {
    const { duplicateSales } = this.state;

    if (duplicateSales.length === 0) return null;

    return (
      <Box title="Ventas Duplicadas" icon="warning">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Code</th>
              <th>Payment Method</th>
              <th>Amount</th>
              <th>TimeStamp</th>
              <th>RedsysCode</th>
            </tr>
          </thead>
          <tbody>
            {duplicateSales.map((sale) => (
              <tr key={sale._id}>
                <td>{sale.Code}</td>
                <td>{sale.PaymentMethod}</td>
                <td>{sale.Amount}</td>
                <td>{new Date(sale.TimeStamp).toLocaleString()}</td>
                <td>{sale.RedsysCode}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    );
  }

  render() {
    const { conflictCount } = this.state;

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              CONFLICTOS DE VENTAS ({conflictCount})
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <SalesFilterForm />
        </Box>
        {this.renderDuplicateSales()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sales: state.sales.sales,
  error: state.sales.error,
  loading: state.sales.loading,
  auth: state.auth,
  form: state.form,
  machines: state.machines.machines,
  role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
  fetchSales: bindActionCreators(fetchSales, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesConflictsView)


