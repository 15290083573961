import React from 'react'
import { Provider } from 'react-redux'
import { Switch, Route } from 'react-router'
import { ConnectedRouter } from 'react-router-redux'

import App from './App'

import { requireAuthentication } from '../components/AuthenticatedComponent'
import LoginAdminView from '../views/LoginAdminView'
import LoginCustomerView from '../views/LoginCustomerView'
import PasswordRecoveryView from '../views/PasswordRecoveryView'
import PasswordAssignView from '../views/PasswordAssignView'
import PasswordRecoveryClientView from '../views/PasswordRecoveryClientView'
import PasswordAssignClientView from '../views/PasswordAssignClientView'

import 'bootstrap/dist/css/bootstrap.css';
import '../style.css'

class Root extends React.Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <ConnectedRouter history={this.props.history}>
          <Switch>
            <Route exact path="/admin" component={LoginAdminView} />
            <Route exact path="/login" component={LoginCustomerView} />
            <Route exact path="/passwordrecovery" component={PasswordRecoveryView} />
            <Route exact path="/passwordassign" component={PasswordAssignView} />
            <Route exact path="/passwordrecoveryclient" component={PasswordRecoveryClientView} />
            <Route exact path="/passwordassignclient" component={PasswordAssignClientView} />
            <Route path="/" component={requireAuthentication(App)} />
          </Switch>
        </ConnectedRouter>
      </Provider>

    )
  }
}

export default Root
