import {
  TEST_RENTALS_REQUEST, TEST_RENTALS_SUCCESS, TEST_RENTALS_FAILURE
} from '../actions/tests'

const INITIAL_STATE = {
  rentalstest: null, error: null, loading: false
}

const testsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case TEST_RENTALS_REQUEST:
        return {
          ...state,
          rentalstest: null, error: null, loading: true
        }
      case TEST_RENTALS_SUCCESS:
        return {
          ...state,
          rentalstest: action.response, error: null, loading: false
        }
      case TEST_RENTALS_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      default:
        return state
    }
  }

export default testsReducer
